/* <span><Icon class={item.icon} icon={item.icon}></Icon></span> */
.service__item{
    padding: 20px;
    /* background: var(--card-bg-01); */
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
    border-radius: 10px;
    cursor: pointer;
}

.service__item span i{
    font-size: 2.5rem;
    /* font-size: 4.5rem; */
    background: var(--primary-color);
    padding: 10px;
    border-radius: 50%;
    color:#fff;
    font-weight: 400 !important;
}

.service__item h3{
    color: var(--primary-color);
    font-size: 1.2rem;
    font-weight: 700;

}

@media only screen and (max-width:992px){
    .service__item span i{
        font-size: 1.8rem;
        padding:5px;
    }
    .service__item{
        margin-bottom: 20px;
    }
}

@media only screen and (max-width:768px){
    .service__item{
        /* font-size: 1.8rem;
        padding:5px;
         */
        margin-bottom: 1.1rem;
        /* padding: 10px; */
        /* margin-left: 2rem; */
        }
    .service__item:first-child{
        margin-left: 30px;
    }

}
@media only screen and (max-width:576px){
    .service__item{
        padding:20px;
        margin-right: 40px;
    }
}