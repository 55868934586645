p{
    margin-bottom: 30px;
    color: black;
    line-height: 30px;
    /* padding-left: 0px; */
    /* padding-right: 20px; */
}
#about__us{
    padding-left: 30px;
    padding-right: 30px;
}