.product__item{
    cursor: pointer;
    /* border: .1px solid var(--primary-color);
    padding: 5px;
    border-radius: 2%; */
}
#product__img{
    /* width: 300px; */
    height: 290px;
}
@media only screen and (max-width:768px){
    #product__img{
        /* width: auto; */
        height: auto;
    }
    
}
/* @media only screen and (max-width:768px){
    .card-title.product__name {
        overflow: auto;
        text-overflow: auto;
         white-space: auto; 
        max-width: auto; 
    }
    
}


.card-title.product__name {
    overflow: hidden;
    text-overflow: ellipsis;
     white-space: nowrap; 
    max-width: 300px;  
} */

.product__info h3 {
    font-size: 1.3rem;
    color:var(--primary-color);
    font-weight: 600;
    margin-top: 15px;
    /* font-style: italic; */
}
.product__info a:hover{
    color:inherit,
}

.card-title a:hover {
    /* color: #fa9c23; */
    text-decoration: none;
  }
.product__info span {
    font-size: 0.9rem;
}

.product__card-bottom .price{
    color: var(--primary-color);
    font-size: 1.1rem;
    font-weight: 500;
}

.product__card-bottom span i{
    font-size: 1.2rem;
    padding: 5px;
    background: var(--primary-color);
    color: #fff;
}
#symbol{
    font-size: 1rem;
    /* padding: 5px; */
    background: none;
    color: var(--primary-color);
}
.view-btn{
    font-size: 1.2rem;
    padding: 5px;
    background: var(--primary-color);
    color: #fff;
    /* border-radius: %; */
}
.view-btn {
    /* background-color: #fa9c23; */
    color: white;
  }