
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");


/*  css variables */
:root {
  --card-bg-01: #fdefe6;
  --card-bg-02: #d6e5fb;
  --card-bg-03: #ceebe9;
  --card-bg-04: #e2f2b2;
  --primary-color: #0a1d37;
  --hero-bg: #d6e5fb;
  --heading-fontSize: 2rem;
  --small-text-color: #999;
  --heading-text-color: #0a1d37;
}

/* reset browser default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

p {
  color: var(--small-text-color);
  font-size: 1rem;
}

h1,
h2 {
  font-size: var(--heading-fontSize);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

img{
  width: 100%;
}

section{
  padding: 60px 0px;
}

.section__title{
  color: var(--primary-color);
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .section{
    padding: 40px 0;
  }
}
/* 
 html,
 body {
   font-family: 'Amazon Ember';
   width: 100%;
   height: 100%;
   margin: 0px;
   padding: 0px;
   overflow-x: hidden;
 }
 

 
 ::-webkit-scrollbar-track {
   background-color: #f5f5f5;
 }
 
 ::-webkit-scrollbar {
   width: 8px;
   background-color: #f5f5f5;
 }
 
 ::-webkit-scrollbar-thumb {
   background-color: rgba(66, 66, 66, 0.2);
   border: 0px;
   background-clip: padding-box;
   border-radius: 5px;
 }
 
 nav {

   background-color: #000000;
   padding: 1rem 1rem;
 }
 
 #search_btn {
   background-color: #febd69;
 }
 
 .input-group {
   width: 90%;
   margin-right: 35%;
 }
 
 #search_field {
   height: 2.4rem;
   padding: 1rem;
 }
 
 #login_btn,
 #view_btn {
 
   background-color: #febd69;
   padding: 0.4rem 1.8rem;
   font-family: calibri;
 }
 
 #cart {
   font-size: 1rem;
   color: white;
 }
 
 #cart_count {
   background-color: #febd69;
   padding: 0.2rem 0.5rem;
   border-radius: 0.2rem;
   color: black;
   font-weight: bold;
   font-size: 0.9rem;
 }

 #products_heading {
   margin-top: 1.8rem;
 }
 
 .card {
   height: 100%;
 }
 
 .card-title a {
   color: #2e2e2e;
 }
 
 .card-title a:hover {
   color: #fa9c23;
   text-decoration: none;
 }
 
 .card-body {
   padding-left: 0;
 }
 
 .card-text {
   font-size: 1.4rem;
 }
 
 #view_btn {
   background-color: #fa9c23;
   color: white;
 }
 
 .card-img-top {
   width: 200px;
   height: 150px;
 }
 */
 .ratings {
   font-size: 1.2rem;
   color: #fdcc0d;
 }
 
 #no_of_reviews {
   font-size: 0.85rem;
   color: grey;
   margin-left: 0.5rem;
 }
 /*
 footer {
   margin-top: 8rem;
   color: grey;
   bottom: 0;
   width: 100%;
 }
 */
 
 .rating-outer {
   display: inline-block;
   position: relative;
   font-family: FontAwesome;
   /* color: var(--primary-color); */
   color: #223e65;
 }
 
 .rating-outer::before {
   content: '\f006 \f006 \f006 \f006 \f006';
 }
 
 .rating-inner {
   position: absolute;
   top: 0;
   left: 0;
   white-space: nowrap;
   overflow: hidden;
   width: 0;
 }
 
 .rating-inner::before {
   content: '\f005 \f005 \f005 \f005 \f005';
   /* color: var(--primary-color); */
   color: #223e65;
  
  
 }
 /*
 #product_id {
   color: grey;
   font-size: 0.8rem;
 }
 
 #product_price {
   font-size: 2rem;
   font-weight: bold;
 }
 
 #cart_btn,
 #review_btn {
   border-radius: 2rem;
   background-color: #fa9c23;
   border: none;
   padding: 0.5rem 2rem;
 }
 
 #product_seller {
   color: grey;
   font-size: 0.9rem;
 }
 
 */
 #stock_status {
   font-size: 1.1rem;
   font-weight: bold;
   margin-left: 0.3rem;
 }
 
 .redColor {
   color: red;
 }
 
 .greenColor {
   color: green;
 }
 
 #product_image {
   margin-top: 9rem;
 }
 
 .loader {
   display: block;
   margin-left: auto;
   margin-right: auto;
   margin-top: 20%;
   width: 80px;
   height: 80px;
   padding-left: 0;
 }
 .loader:after {
   content: ' ';
   display: block;
   width: 64px;
   height: 64px;
   margin: 8px;
   border-radius: 50%;
   border: 6px solid #fa9c23;
   border-color: var(--primary-color) transparent;
   animation: lds-dual-ring 1.2s linear infinite;
 }
 @keyframes lds-dual-ring {
   0% {
     transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /*
 
 
 
 .cart-item {
   margin: 1.8rem 0rem;
   padding: 0rem 0.7rem;
 }
 
 .cart-item a {
   color: grey;
 }
 
 #delete_cart_item {
   color: red;
   background: white;
   padding: 0.3rem 0.5rem;
   font-size: 1.1rem;
   border: none;
 }
 
 #card_item_price {
   color: #febd69;
   font-weight: bold;
   font-size: 1.4rem;
 }
 
 #checkout_btn,
 .review-btn {
   background-color: #fa9c23;
   border-color: #fa9c23;
   margin-top: 2rem;
   border-radius: 5rem;
 }
 
 #view_order_details {
   background-color: #fa9c23;
   border-color: #fa9c23;
 }
 
 #order_summary {
   border: 1px solid #e3e3e3;
   border-radius: 1rem;
   padding: 2rem 1.5rem;
 }
 
 .order-summary-values {
   float: right;
   font-weight: bold;
 }
 
 .stockCounter input {
   border: none;
   width: 3rem;
   text-align: center;
 }
 
 .plus,
 .minus {
   padding: 0.1rem 0.5rem;
 }
 
 .stockCounter input::-webkit-outer-spin-button,
 .stockCounter input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }
 
 .form-control:disabled,
 .form-control[readonly] {
   background-color: white;
 }
 

 
 .wrapper {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 5rem;
 
   font-weight: 700;
 }
 
 .wrapper form {
   padding: 2.5rem 3rem;
 }
 
 .wrapper form .btn {
   background-color: #fa9c23;
   border-color: #fa9c23;
   color: white;
   margin-top: 2.5rem;
 }
 
 .wrapper form a {
   font-size: 0.9rem;
   color: grey;
 }
 */

 .avatar {
   display: inline-block;
   margin-bottom: 0;
   height: 3rem;
   width: 3rem;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   border-radius: 50%;
 }
 
 .avatar img {
   width: 100%;
   height: 100%;
   object-fit: cover;
 }
 
 .avatar-nav {
   margin-right: 0.6rem;
   height: 2.2rem;
   width: 2.2rem;
  }
  
  .avatar-profile {
    height: 16rem;
    width: 16rem;
  }
  
 .rounded-circle {
   border-radius: 50% !important;
 }
 /*
 .btn.focus,
 .btn:focus {
   outline: 0;
   box-shadow: none;
 }
 

 

 
 
 */
 .checkout-progress div {
   box-sizing: border-box;
 }
 
 .checkout-progress {
   display: block;
   clear: both;
   margin: 20px auto;
   width: auto;
   font-family: sans-serif;
   overflow: auto;
 }
 
 .step {
   margin: 0;
   border: 0;
   letter-spacing: 1px;
   line-height: 30px;
   padding: 5px 10px 5px 15px;
   color: grey;
   text-decoration: none;
   cursor: default;
   font-weight: bold;
   float: left;
   height: auto;
 }
 
 .incomplete {
   background: #eeeeee;
 }
 
 .active-step {
   background: var(--primary-color);
   color: #fff;
 }
 .triangle-active {
   float: left;
   width: 0;
   border-top: 20px solid transparent;
   border-left: 15px solid var(--primary-color);
   border-bottom: 20px solid transparent;
   margin-left: -1px;
 }
 
 .triangle2-active {
   width: 0;
   float: left;
   border-top: 20px solid var(--primary-color);
   border-left: 15px solid #fff;
   border-bottom: 20px solid var(--primary-color);
   margin-right: -1px;
 }
 
 .triangle-incomplete {
   float: left;
   width: 0;
   border-top: 20px solid transparent;
   border-left: 15px solid #eeeeee;
   border-bottom: 20px solid transparent;
   margin-left: -1px;
 }
 
 .triangle2-incomplete {
   width: 0;
   float: left;
   border-top: 20px solid #eeeeee;
   border-left: 15px solid #fff;
   border-bottom: 20px solid #eeeeee;
   margin-right: -1px;
 }
 

 /*
 .user-info h4 {
   font-weight: 800;
   color: grey;
   margin-top: 2rem;
  }
 
 .user-info p {
   font-size: 1.1rem;
 }
 
 #edit_profile {
   background-color: #fa9c23;
   border-color: #fa9c23;
 }
 

 
 .order-confirm p,
 .order-details p {
   margin-left: 1.5rem;
 }
 
 .update-btn {
   background-color: #fa9c23;
   border-color: #fa9c23;
   color: white;
 }
 
 .update-btn:hover {
   color: white;
 }
 
 
 .page-item.active .page-link {
   background-color: #fa9c23;
   border-color: #fa9c23;
 }
 
 .page-link {
   color: #fa9c23;
 }
 
 .page-link:hover {
   color: #fa9c23;
 }

 
 .sidebar-wrapper {
   display: flex;
   width: 100%;
   align-items: stretch;
   min-height: 100vh;
   font-weight: normal;
   
   margin-top: 0;
   height: 100%;
   left: 0;
   top: 0;
   z-index: 10;
  }
  #sidebar {
   min-width: 100%;
   max-width: 100%;
   background: #232f3e;
   color: #fff;
   transition: all 0.3s;
  }
  #sidebar.active {
    margin-left: -250px;
  }
  
  #sidebar .sidebar-header {
    padding: 20px;
   background: #232f3e;
  }
 #sidebar ul.components {
   padding: 5px 0px;
   border-bottom: 1px solid #232f3e;
  }
  
  #sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: white;
    text-decoration: none;
  }
  #sidebar ul li a:hover {
    color: #232f3e;
    background: #fff;
  }
  
  #sidebar ul li a i {
    margin-right: 0.3rem;
  }
  
  #sidebar ul li.active > a,
  a[aria-expanded='true'] {
    color: #fff;
    background: #232f3e;
  }
  a[data-toggle='collapse'] {
    position: relative;
  }
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  */
  /*
  
 ul ul a {
   font-size: 1rem !important;
   padding-left: 30px !important;
   background: #232f3e;
 }
 
 ul.CTAs {
   padding: 20px;
 }
 
 ul.CTAs a {
   text-align: center;
   font-size: 0.9em !important;
   display: block;
   border-radius: 5px;
   margin-bottom: 5px;
 }
 

 #products_table .btn {
   padding: 0.2rem 0.5rem;
   margin-left: 0.4rem;
 }
 

 
 .card-font-size {
   font-size: 1.5rem;
 }
 

 */
 .rating {
   margin-top: 10rem;
 }
 
 .stars {
   height: 100px;
   display: flex;
   align-items: center;
   padding-left: 0;
 }
 
 .star {
   display: inline;
   list-style: none;
   font-size: 3rem;
   padding-left: 0.9rem;
   color: #e3e3e3;
 }
 
 .star:first-child {
   padding-left: 0;
 }
 
 .orange {
   color: #fa9c23;
 }
 
 .yellow {
   color: #fdcc0d;
 }
 
 .review_user {
   font-size: 0.8rem;
   color: grey;
 } 
 /*
 */