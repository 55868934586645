.hero__section{
    background: var(--hero-bg);
}

.hero__content h2{
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 600;
    margin: 20px 0px;
    /* padding-left: 20px; */

}

.hero__content p{
    color:#111;
    line-height: 30px;
    margin-bottom: 10px;
}

.hero__section p:nth-child(1){
    font-weight: 500;
}
.shop__btn{
    border: none;
    outline: none;
    padding: 8px 20px;
    border-radius: 5px;
    background: var(--primary-color);
    color:#fff;
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 40px;
    /* margin-right: 10px; */
}
/* .call__now{
    border: none;
    outline: none;
    padding: 8px 20px;
    border-radius: 5px;
    background: var(--primary-color);
    color:#fff;
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 40px;
} */
.shop__btn:hover{
    color:#fff;
    text-decoration: none;

}

.hero__content{
    padding-top: 50px;
}

.timer__count{
    background: var(--primary-color);
    height: 300px;
}
.timer__count img{
    widows: 50% !important;
    height: 50% !important;
    object-fit: contain;
}

.visit__btn{
    background: white;
    color:var(--primary-color);
    font-weight: 600;
    font-size: .7rem;

}

.visit__btn:hover{
    color:var(--primary-color);
}


@media only screen and (max-width:992px){
    .hero__content h2{
        font-size: 2rem;
    }   
    .counter__img{
        display: none;

    }
    .clock__top-content{
        text-align: center;
    }
    .clock__top-content h4{
        font-size: 0.9rem;
    }
    .clock__top-content h3{
        font-size: 1rem !important;
    }
    .count__down-col{
        text-align: center;
        /* justify-content: center; */
    }
}


@media only screen and (max-width:768px){
    .hero__content h2{
        font-size: 1.6rem;
        /* margin-left: 20px; */

    }   
    .hero__content p{
        font-size: 1rem;
        /* margin-left: 20px; */
    }
    .hero__content{
        margin-left: 20px;

    }
    .shop__btn{
        font-size: 0.9rem;
    }
}

@media only screen and (max-width:576px){

    .hero__content{
        padding-top: 0;
        margin-bottom: 40px;
    }
}

 @media only screen and (max-width: 999px) { 
    .hero__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .shop__btn {
      margin-bottom: 10px;
      margin-right: 20px;
    }
    .call__now{
        padding: 8px 35px;
    }
    .shop__btn:nth-of-type(2) {
      margin-top: 10px; 
    }
  }  

 

   
  