@media only screen and (max-width:992px){
    .clock__wrapper{
        justify-content: center;
        /* padding-right: 40px; */
        margin-left: 20px;
        margin-right: 20px;

        /* padding-left: 10px; */
        font-size: 0.9rem !important;
    }
    .clock__data h1{
        font-size: 0.8rem !important;
    }
    .clock__data h5{font-size: 0.9rem !important;}
}