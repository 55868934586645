.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    /* height: 100px; */
    font-weight: 700;
    /* height: 200px; */
    /* width: 70%; */

  }
  
  .wrapper form {
    padding: 2.5rem 3rem;
  }
  
  .wrapper form .btn {
    background-color: #fa9c23;
    border-color: #fa9c23;
    color: white;
    margin-top: 2.5rem;
  }
  
  .wrapper form a {
    font-size: 0.9rem;
    color: grey;
  }

  #login_button{
    border: none;
    outline: none;
    padding: 8px 20px;
    border-radius: 5px;
    background: var(--primary-color);
    color:#fff;
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 5px;
    /* margin-right: 10px; */
    /* height: 60px; */
}

#login_button:hover{
    color:black;
    border: 1px solid black;
    background: white;
    text-decoration: none;
}

#login_button {
  position: relative;
}

#login_button .loaderr {
  position: absolute;
  top: 18%;
  left: 45%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: #000;
  animation: loader 0.8s ease-in-out infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}