.filter__widget select{
    padding: 10px 20px;
    border:1px solid var(--primary-color);
    cursor:pointer;
    border-radius: 5px;
    background: var(--primary-color);
    color: white;
    text-align: center;
}
.filter__widget{
    margin-bottom: 20px;
    /* margin-top: 20px; */
    align-items: center;
    margin-left: 50px;
}

.filter__widget select:focus{
    outline: none !important;
}

.search{
    margin-bottom: 20px;
}
