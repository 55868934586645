.footer{
    padding-top: 60px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background: var(--primary-color);
}


.footer__text{
    line-height: 30px;
    color: white;
}

.footer__quick-links ul li{
    background: transparent !important;
    
}

.footer__quick-links ul li a{
    color: white;  
}

.quick__links-title{
    font-weight: 600;
    color: white;
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.footer__content li,
 .footer__content li p
 {
    color: white;
}
.footer__content li span{
    /* margin-top: 0; */
    font-size: 1.2rem;
    margin-right: 10px;

}

.footer__content li p{
    font-size: 0.9rem;
}
.footer__copyright{
    color: white;
    font-size: 0.9rem;
    margin-top: 40px;
    text-align: center;

}